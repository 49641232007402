.cpo-video-wrap {
  text-align: center;
}

.cpo-video-wrap form {
  display: flex;
  flex-direction: column;
  margin: 25px auto;
  width: 50%;
}

.cpo-video-wrap form input {
  height: 35px;
  margin-left: 10px;
  margin-top: 25px;
  width: 30%;
  padding-left: 5px;
  border: 1px solid;
}

.success-image {
  width: 30%;
  height: auto;
  border-radius: 50%;
  margin-top: 10px;
  min-width: 50px;
  max-width: 500px;
}