.dealer-detail-wrap {
  .dealerDetail {
    div {
      width: 50%;
    }
    padding: 0 0 35px 0;
  }
  p {
    margin: 15px 0;
  }

  code {
    display: block;
    margin: 15px 0;
  }
}

.add-dealer-form {
  .flex-row {
    justify-content: space-between;
  }
  .input-label-wrapper {
    margin-top: 5px;
    width: 100%;
  }
}

.qbp-submission-wrap .bike-form-input-wrapper {
  width: 88%;
  margin: 0 auto;
}

.qbp-submission-wrap .flex-wrap {
  display: flex;
  flex-direction: column;
}

.qbp-submission-wrap .section-title {
  margin: 0 0 0.5em 0;
}

.qbp-submission-wrap .list-wrap {
  box-sizing: border-box;
  padding: 35px 35px;
  border: 1px solid #000;
  margin-bottom: 1em;
}

.edit-email-button {
  border: none;
  margin-left: auto;
  cursor: pointer;
}

.edit-email-input-wrapper {
  display: flex;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
}

.edit-email-input {
  border: none;
  background: none;
  cursor: text;
  font-style: italic;
  font-size: 1em;
  height: 25px;
  width: 100%;
  padding-left: 5px;
}

.confirm-edit {
  cursor: pointer;
  background: none;
  padding: 2px;
  border: none;
  display: inline;
}

.qbp-submission-wrap .label-value-wrapper {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #edecec;
}

.qbp-submission-wrap .label {
  font-weight: 600;
  margin-right: 20px;
}

.qbp-submission-wrap .bike-image,
.syb-detail-wrap .bike-image {
  margin: 0 auto;
  display: block;
  width: 100%;
  height: auto;
  min-width: 70px;
  max-width: 400px;
}

.syb-detail-wrap .info-bike-image {
  margin: 0 auto;
  display: block;
  width: 100%;
  height: auto;
  min-width: 300px;
  max-width: 480px;
}

.qbp-submission-wrap .image-wrap,
.syb-detail-wrap .image-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100px;
  max-height: 300px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 2em;
}

.syb-detail-wrap .info-image-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.qbp-submission-wrap .carousel .slide {
  background: none;
}

.qbp-submission-wrap .carousel-status {
  color: #fff !important;
  text-shadow: none !important;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 2px;
}

.carousel .carousel-slider .control-arrow {
  margin: 0;
}

.qbp-submission-wrap .image-wrap {
  margin: 0 auto;
  width: 50%;
  min-width: 200px;
  max-width: 400px;
}

.carousel .control-dots .dot {
  background: #000;
  box-shadow: none;
  bottom: 8px;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  background: rgba(0, 0, 0, 0.5) !important;
  z-index: 1;
  height: 100%;
}

.qbp-submission-wrap .dealer-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}

.dealer-value {
  margin-top: 0.5em;
  padding-bottom: 5px;
  border-bottom: 1px solid #edecec;
}

.qbp-submission-wrap .shipping-wrap {
  display: flex;
}

.qbp-submission-wrap .shipping-wrap .input-label-wrapper {
  margin: 0;
}

.partner-detail-section {
  .input-label-wrapper {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 25px;
  }
}

.qbp-submission-wrap .restricted-user {
  display: flex;
  color: red;
  margin-bottom: 5px;
}

.qbp-submission-wrap .toggle-restricted-modal-button {
  margin-right: 10px;
}

@media only screen and (max-width: 800px) {
  .qbp-submission-wrap .flex-wrap {
    flex-direction: column;
    justify-content: center;
  }

  .qbp-submission-wrap .list-wrap {
    margin: 0 auto 25px;
    width: 90%;
  }
}

.form-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

p {
  margin: 0;
}

.input-label-wrap-status {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  gap: 0.25em;
}

.offer-display {
  flex: 1;
  margin-right: 20px;
}

.doo-label {
  max-width: 90px;
}

.doo-display {
  flex: 1;
}

.backdrop {
  .message {
    min-height: 350px;
  }
}

.status-wrap {
  /* border: 1px solid #000; */
  padding: 25px;
}

.offer-input-label-wrap {
  margin-right: 0;
}

.underline {
  padding-bottom: 5px;
  border-bottom: 1px solid lightgray;
  margin-bottom: 2em;
}

.note-label {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
}

.label {
  font-weight: 900;
  font-size: 13px;
  font-family: 'GT America Condensed Bold', sans-serif;
}

.close-modal-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  cursor: pointer;
  background: none;
  border: none;
  background-image: url('../../images/cancel.png');
  background-size: cover;
  background-repeat: none;
  width: 30px;
  height: 30px;
}

.modal-image-wrap {
  position: relative;
  max-height: 98vh;
  max-width: 98vw;
}

.rotate-btn {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000000000000000;
  margin-top: 0;
}

.button-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.offer-button {
  margin: 25px 25px 0;
}

.message.offer-decline-message {
  height: 600px;
  max-width: 700px;
  min-width: 200px;
  position: relative;
}

.message.offer-decline-message.offer-decline-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.offer-decline-form {
  text-align: center;
  width: 100%;
}

.offer-decline-form .submit-button {
  display: inline-block;
  margin: 20px auto 0 auto;
}

.offer-decline-form.input-label-wrapper {
  margin: 0 !important;
}

.qbp-input-label-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 70%;
  margin: 20px auto;
}

.decline-button {
  background: none;
  border: 1px solid #e92338;
  color: #e92338;
  transition: border 0.2s, color 0.2s;
}

.externalnote::after {
  content: ' - external note';
  font-size: 11px;
  font-style: italic;
  color: #afafaf;
  margin-bottom: 12px;
}

.decline-button:hover {
  border: 1px solid rgb(250, 43, 55);
  background: none;
  color: rgb(250, 43, 55);
}

.complete-button {
  background: none;
  border: 1px solid #00c160;
  color: #00c160;
  transition: border 0.2s, color 0.2s;
}

.complete-button:hover {
  background: none;
}

.submission-notes {
  overflow: scroll;
  text-align: left;
  padding: 0;
  min-height: 30px;
  max-height: 300px;
}

.note {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid lightgray;
  list-style: none;
}

.internal {
  color: #00c160;
}

.note-text {
  flex: 1;
  margin-right: 5px;
}

.update-message {
  position: absolute;
  top: 140px;
  text-align: center;
  margin: 0 auto;
}

.success-message {
  font-family: 'GT America Bold', sans-serif;
  text-align: center;
  margin-bottom: 25px;
}

@media only screen and (max-width: 800px) {
  .status-form-wrap {
    margin: 0 auto;
  }
}
