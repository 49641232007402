fieldset {
  border: none;
  padding: 0;
}

.login-header {
  width: 100%;
  height: 75px;
  background-color: #222;
  display: flex;
  align-items: center;
}

.login-header img {
  height: 50px;
}

.password-input-wrap {
  position: relative;
  width: 300px;
  margin: 0 auto;
}

.password-input-wrap img {
  position: absolute;
  right: 0;
  top: 0;
  height: 25px;
  margin: 7px;
}

.forgot {
  width: 300px;
  margin: 0 auto;
  text-align: left;
}

.form-control {
  -webkit-appearance: none;
  border-radius: 0;
  width: 100%;
  min-width: 150px;
  max-width: 300px;
  height: 40px;
  margin-bottom: 25px;
  padding-left: 10px;
  background: none;
  border: 1px solid black;
  -webkit-box-shadow: 0 0 0 30px #fff inset;
  -moz-box-shadow: 0 0 0 30px #fff inset;
  box-sizing: border-box;
}

.captcha-display {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  max-width: 250px;
  margin: 25px auto;
}

.small-control {
  text-decoration: none;
  font-style: italic;
  background: none;
  font-size: 0.7em;
  border: none;
  color: #00c160;
  cursor: pointer;
  padding: 0;
}

.signup {
  padding-top: 0.5em;
}

.tradeup-logo {
  width: 17%;
  min-width: 150px;
  max-width: 400px;
  height: auto;
  margin-top: 4em;
}

.welcome-message {
  margin: 25px auto 3em auto;
  font-size: 13px;
  text-align: center;
}

.btn-success {
  margin: 0 0 15px 0 !important;
}

.login-error {
  color: #ed1c24;
  margin: 10px 0 0 10px;
}

@media screen and (max-width: 410px) {
  .login-error {
    margin: 0 0 0 10px;
  }
}
