.iiz.image-zoom-container {
  overflow: none;
  width: 100%;
  height: 100%;
}
.image-zoom-container > div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.image-zoom-container > div > .iiz__img {
  object-fit: contain;
  max-height: 568px;
  overflow: none;
}
