.home-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 25px;
  justify-content: center;
}

.drivetrain-logo {
  width: 17%;
  min-width: 150px;
  max-width: 400px;
  height: auto;
}

.home-wrap:visited {
  border: 1px solid;
}

.home-link {
  color: #000;
}

.section-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%;
  width: 250px;
  height: 250px;
  border: 2px solid;
  margin: 0 0px 25px 25px;
  padding: 25px;
  transition: border 0.2s, color 0.2s;
}

.section-wrap:hover {
  border: 2px solid #00c160;
  color: #00c160;
}

.section-wrap span {
  font-size: 12px;
  letter-spacing: 1px;
}

.section-wrap:hover * {
  color: #00c160;
}
