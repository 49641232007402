.inspection-detail-wrap {
  td {
    text-align: center;
  }

  .fieldset {
    width: 100%;
  }
}

.two-button-wrapper {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.view-pdf-button {
  border: 1px solid;
  font-weight: 900;
  height: 40px;
  transition: background 0.2s, color 0.2s;
}

.view-pdf-button:hover {
  background: #000;
  color: #fff;
  cursor: pointer;
}

.two-button-wrapper .submit-button {
  margin: 0;
}

.pd-wrap p {
  margin: 10px 0;
}

/* =============== three-sixty 360 detail view =================== */
.three-sixty-page {
  label {
    font-weight: 900;
    font-size: 13px;
    font-family: 'GT America Condensed Bold', sans-serif;
    margin-right: 5px;
  }
}

.service-level-360 {
  font-family: 'GTA America Condensed Bold', sans-serif;
  top: 0;
  color: #fff;
  margin: 0 auto;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.product-info {
  display: flex;
  flex-wrap: wrap;
  width: 88%;
  margin: 10px auto;

  iframe {
    height: 350px;
  }
}

.product-info div {
  flex: 1;
}

.one-third-wrap {
  display: flex;
  flex-direction: column;
  margin: 0 20px 0 0;
}

.full-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 25px;
  padding-top: 25px;
  border-top: 5px solid #dfdfdf;
}

.full-wrap div {
  flex: 1;
  display: flex;
}

.full-wrap div .three-sixty-wrap {
  flex: 1;
}

.full-wrap .three-sixty-wrap {
  margin-right: 25px;
}

.three-sixty-wrap {
  border-bottom: 1px solid #dfdfdf;
  height: 25px;
  margin-top: 10px;
  margin-bottom: 2px;
}

.three-sixty-wrap label {
  font-size: 1em;
}

.table-title {
  margin-bottom: 0;
}

.product-photo {
  width: 320px;
  height: auto;
}

.detail-purchase {
  border-bottom: 1px solid black;
  width: 88%;
  padding: 25px;
}

.react-table-detail {
  margin-bottom: 0;
}

.react-table-detail .ReactTable {
  margin-bottom: 50px;
}

.depreciation-table {
  border: 1px solid;
  width: 25%;
  max-width: 500px;
  min-width: 300px;

  &:last-of-type {
    margin-left: 25px;
  }
  tr {
    height: 25px;

    td {
      padding-left: 10px;
      border-color: #a2a2a2;
      text-align: center;
    }
  }
}

.cpo-submission-wrap {
  display: flex;
  width: 88%;
  margin: 0 auto;
  justify-content: space-around;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.submission-wrap {
  width: 88%;
  margin: 0 auto;
  margin-bottom: 25px;
  padding-bottom: 25px;
}

.submission-wrap .flex-column {
  width: 50%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-around {
  justify-content: space-around;
}
/* =============== end three-sixyt 360 detail view =================== */
