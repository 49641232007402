.factorVector input {
  width: 50px;
  margin: 20px 1px;
}

.monthFactor {
  border: 1px solid black;
  margin: 0 10px;
  padding: 10px;
}

.monthFactor label {

}

.factor {
  text-align: right;
}
