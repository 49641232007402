.backdrop {
  .message {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-width: 500px;
    min-height: 300px;
    max-width: 1200px;
    width: 80%;
    border-radius: 3px;
    z-index: 2;
    background: #fff;
    padding: 50px;
  }
}

.edit-inspection-modal-form {
  width: 100%;
  box-sizing: border-box;
  padding: 3rem;
}

.edit-message {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  height: 100%;
  width: 100%;
  background: #fff;
  padding: 50px 0;
}

.edit-sku-input {
  border: none;
  font-size: 1.2em;
}

.inner-modal {
  width: 33%;
}

.flex-column-center {
  display: flex;
  flex-direction: column;

  .classy-button {
    width: 100%;
  }
}

.flex-adjacent {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 10px auto;
  max-width: 800px;
}

.flex-adjacent div {
  flex: 1;
  min-width: 150px;
}

.edit-sku-button {
  border: none;
  cursor: pointer;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 0;
}

.pdf-message {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px;
  background: #fff;
}

.print-pdf {
  margin-bottom: 25px;
  border: 1px solid #000;
  background: #fff;
  color: #000;
  transition: background 0.2s, color 0.2s;
}

.print-pdf:hover {
  background: #000;
  color: #fff;
}

.edit-message fieldset {
  width: 100%;
}

.restricted-message {
  margin: 25px;
  letter-spacing: 1px;
}

.modal-image {
  z-index: 100000000;
  height: auto;
  width: auto;
  max-height: 95vh;
}


.id-image {
  max-width: 500px;
  min-width: 400px;
  min-height: 300px;

}

.threesix-image-wrap {
  position: relative;
  min-width: 300px;
}

.threesix-image {
  min-width: 300px;
  max-width: 95vw;
  z-index: -1;
}

.threesix-close {
  position: absolute !important;
}

.modal-title {
  font-size: 1.5em;
  text-align: center;
}

.sign-up-modal {
  height: 700px !important;
  padding-bottom: 25px;
}

.doo-error {
  color: red;
  font-size: .7em;
}

.check-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.feather-check-circle {
  width: auto;
  color: #00c160;
}

.feather-x-circle {
  width: auto;
  color: red;
}

.offer-decline-form .submit-button {
  width: 100%;
}

#pdf-view {
  display: flex;
  height: 500px;
  min-width: 500px;
  justify-content: center;
}

@media screen and (max-width: 650px) {
  div .message {
    width: 100%;
    padding: 10px;
    min-width: 300px;
  }

  .sign-up-modal {
    padding-bottom: 0;
  }

  div .message span .input-label-wrapper {
    margin-top: 5px;
  }
}

@media screen and (max-width: 321px ) {
  div .message {
    min-width: 280px !important;
    padding: 5px;
  }
}

.close-modal {
  position: fixed;
  right: 45px;
  top: 50px;
  margin: 10px;
  cursor: pointer;
  background: none;
  border: none;
  background-image: url('../images/cancel.png');
  background-size: cover;
  background-repeat: none;
  width: 30px;
  height: 30px;
}

/* TODO: code smell. something is broken here at backdrop and no CSS below is readable */
.backdrop {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  padding: 50px;
  overflow-x: scroll;
};








