a {
  text-decoration: none;
}

.navbar {
  background-color: #222;
  height: 100vh;
  z-index: 12;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.5s;
}

.navbar-expanded {
  width: 300px;
}

.navbar-collapsed {
  width: 75px;
}

.nav-helpdesk-link {
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
  cursor: pointer;
  background-color: #00c160;
  text-transform: uppercase;
  padding: 10px 35px;
  border-radius: 4px;
  margin-top: 10px;
}

.navbar-header {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-img {
  width: 100%;
}

.navbar-routes {
  max-height: 65vh;
  overflow-y: auto;
}

.navbar-route {
  color: #b1b1b1;
  display: flex;
  align-items: center;
  padding: 20px 30px;
  transition: 0.5s;
  cursor: pointer;
}

.active-navbar-route-dropdown {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.active-navbar-route,
.active-navbar-route-dropdown,
.navbar-route:hover {
  background-color: black;
}

.navbar-subroutes {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
}

.navbar-subroute:hover {
  text-decoration: underline;
}

.navbar-subroute {
  color: #b1b1b1;
  padding-top: 15px;
}

.navbar-title {
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.navbar-title-text {
  padding: 0 12px;
  text-transform: uppercase;
}

.navbar-user {
  padding-bottom: 30px;
}

.navbar-toggle {
  background-color: #00c160;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  position: absolute;
  transition: 0.5s;
  cursor: pointer;
}

.navbar-toggle-expanded {
  top: 30px;
  left: 285px;
}
.navbar-toggle-collapsed {
  left: 60px;
  top: 45px;
}

.active-navbar-subroute {
  font-weight: 700;
  text-decoration: underline;
}

.navbar-divider {
  height: 2px;
  margin: 20px 30px;
  background-color: #454545;
  width: 15px;
}

.navbar-divider-expanded {
  width: 200px;
}
