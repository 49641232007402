@import url('https://fonts.googleapis.com/css?family=Caveat&display=swap');

.light-attention {
  background: lightblue;
  display: inline;
  padding: 2px 6px;
  border-radius: 7px;
  border: 1px solid #dfdfdf;
}
.signature {
  font-family: 'Caveat', cursive;
  font-size: 28px;
}

.syb-detail-wrap {
  width: 100%;
  padding: 25px 0;
  margin: 0 auto;
  background-color: #fff;
}

.syb-detail-wrap .message.offer-decline-message {
  height: inherit;
}

p.sub-note::before {
  content: '  - ';
}

.sub-note:last-of-type {
  width: 100%;
  border-bottom: 1px solid #dfdfdf;
}

.syb-detail-wrap .flex-row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.syb-detail-wrap .flex-row fieldset p,
.syb-detail-wrap .flex-row fieldset a,
.syb-detail-wrap .flex-column p {
  margin: 5px 0;
}


.syb-detail-wrap .flex-row fieldset:first-of-type {
  margin-right: 25px;
}

// prevent MUI styles from fighting with global styles
.syb-detail-wrap .flex-row [class^="Mui"] fieldset:first-of-type {
  margin-right: unset
}


.relative {
  position: relative;
}

.absolute-right {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 10px;
}


.syb-detial-wrap fieldset {
  flex: 1;
}

.syb-detail-wrap .label {
  font-size: 16px;
}

.syb-detail-wrap table caption {
  background: #5b5b5b;
  color: #fff;
  height: 40px;
  line-height: 40px;
  font-size: 26px;
  border-bottom: 1px solid #000;
}

.syb-detail-wrap .list-wrap {
  padding: 5px 25px 25px;
  margin-top: 25px;
}

.flex-end-row {
  display: flex;
  align-items: flex-end;
}

.syb-detail-wrap fieldset {
  text-align: inherit;
  flex: 1;
  border: 1px solid #000;
}