.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.dropdown-input {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid;
  border-radius: 0px !important;
  -moz-border-radius: none;
  -webkit-border-radius: none;
  width: 50%;
  height: 35px;
}

.centered {
  display: block;
  margin: 20px auto 0 auto;
}

.shadow {
  -moz-box-shadow: 5px 6px 8px 1px #ccc;
  -webkit-box-shadow: 5px 6px 8px 1px #ccc;
  box-shadow: 5px 6px 8px 1px #ccc;
}