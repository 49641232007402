.three-sixty-detail-wrap {
  padding: 25px;
}

.three-sixty-detail-wrap h3 {
  margin-bottom: 0;
}

.three-sixty-detail-wrap .flex-space-between {
  margin-bottom: 0px;
}

.three-sixty-detail-wrap .text-input {
  height: 40px;
  box-sizing: border-box;
}

.text-center {
  text-align: center;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.three-sixty-detail-wrap .full-width {
  margin-right: 25px;
}

.full-width {
  width: 100%;
  max-width: none;
}

.three-sixty-detail-wrap .basic-table {
  margin-top: 20px;
}

.basic-table {
  border: 1px solid;
}

.basic-table caption {
  background: #000;
  color: #fff;
  height: 44px;
  font-size: 18px;
  line-height: 44px;
  font-weight: bold;
}

.basic-table td {
  padding: 5px 10px;
}

.basic-table td a {
  color: #000;
}

.basic-table td a:hover {
  border-bottom: 1px solid;
}