
.inventory_adjustments {
  padding: 10px;
  form {
    width: 600px;
    input {
      margin: 5px; 
      padding: 15px;   
      border-radius: 5px;
      transition: background-color 0.3s;
    }
    input:hover {
      background-color: rgb(129, 129, 129);    
    }
    section {
      display: flex;
      justify-content: space-between;
    }
    .submit {
      background-color: #000;
      color: #fff;    
    }
  }
}