$primaryColor: #00c160;
$disabledPrimary: rgb(90, 199, 144);
$borderGray: #afafaf;

$minWidth: 145px;
$maxWidth: 450px;

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;

  .form-section {
    margin-bottom: 0;
    width: 100%;
    min-width: $minWidth;
    max-width: $maxWidth;
  }

  .gray-small {
    color: #888888;
    font-size: 14px;
  }
}

.tradein-wrap {
  padding: 0 12px;

  button {
    border: none;
    cursor: pointer;
  }

  .invalid {
    color: rgb(55, 55, 55);
    font-size: 12px;
    font-style: italic;
    background: rgba(90, 199, 144, 0.3);
    padding: 2px 5px;
    border-radius: 2px;
    line-height: 10px;
    display: inline-block;
  }

  .submit-btn {
    display: block;
    width: 100%;
    height: 37px;
    min-width: 100px;
    max-width: 150px;
    margin: 25px auto 0 auto;
    border-radius: 5px;
    border: none;
    background: $primaryColor;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s;
  }

  .submit-btn:disabled {
    background: $disabledPrimary;
    color: lightgray;
    cursor: initial;
  }

  .label-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: $minWidth;
    max-width: $maxWidth;
    margin-top: 10px;

    .input {
      height: 33px;
      font-size: 16px;
      padding-left: 3px;
      border: 1px solid #dfdfdf;
    }

    #description {
      height: 60px;
      border-color: lightgray;
    }

    ::placeholder {
      font-size: 16px;
    }
  }
}

.declaration-form-wrap {
  border: 1px solid;
  margin-bottom: 50px;
  padding: 25px;

  .flex-row-adjacent {
    max-width: none;

    .label-wrap:last-of-type {
      margin-left: 10px;
    }
  }

  #state,
  #declineReason {
    background: none;
    height: 38px;
    border-color: #dfdfdf;
  }
}

.flex-row-adjacent {
  display: flex;
  width: 100%;
  min-width: $minWidth;
  max-width: $maxWidth;

  .label-wrap:first-of-type {
    margin-right: 10px;
  }
}

.dropzone {
  display: flex;
  flex-direction: column;
  border: 1px solid #dfdfdf;
  position: relative;
  width: 100%;
  height: 180px;
  min-width: $minWidth;
  max-width: $maxWidth;
  overflow: hidden;

  .close-button {
    cursor: pointer;
    z-index: 10;
    color: red;
    font-weight: bold;
    padding: 2px 3px;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
  }
}

.hovered {
  border-color: $primaryColor;
  transition: border-color 0.8s;
}

.dropzone:first-of-type {
  margin-right: 10px;
}

.file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.image-preview {
  width: 70%;
  height: auto;
}

.progress-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;

  .progress-bar {
    height: 20px;
    background: transparent;
  }

  .inner-progress-bar {
    height: 20px;
    background: $primaryColor;
    transition: width 0.8s;
  }
}

.italic-small {
  font-style: italic;
  margin-top: 25px;
  font-size: 13px;
  color: $borderGray;
}

.min-width-form-wrap {
  min-width: 300px;
  max-width: 1000px;
}

.declaration-form-wrap {
  @media screen and (max-width: 1000px) {
    .DayPickerInput {
      width: 100%;
    }
  }

  .flex-wrap-small {
    @media screen and (max-width: 1000px) {
      flex-wrap: wrap;
      .label-wrap:last-of-type {
        margin-left: 0;
      }
    }

    @media screen and (max-width: 743px) {
      .label-wrap:first-of-type {
        margin-right: 0;
      }
    }
  }
}

.dl-tooltip-button {
  display: inline-block;
  width: 17px;
  height: 17px;
  color: #000;
  border-radius: 50%;
  text-align: center;
  line-height: 17px;
  margin-left: 5px;
  margin-bottom: 2px;
  border: 1px solid #000;
  cursor: pointer;
}

.response-message-wrap {
  min-height: 60vh;
  height: 100%;
  justify-content: center;

  .client-message {
    width: 50%;
    min-width: $minWidth;
    max-width: $maxWidth;
    font-size: 20px;
    margin-top: 15px;
  }

  .client-message-confirm {
    font-size: 17px;
  }

  .submit-btn {
    margin-top: 10px;
  }
}

.react-autosuggest__container {
  position: relative;
}

// .react-autosuggest__input {
//   box-sizing: border-box;
//   width: 100%;
//   min-width: $minWidth;
//   max-width: $maxWidth;
//   height: 33px;
//   padding: 0px 3px;
//   font-size: 16px;
//   border: 1px solid #dfdfdf;
//   border-radius: 4px;
// }

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

$bannerHeight: 50px;

.geo-banner {
  z-index: 1;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: $bannerHeight;
  color: #fff;
  font-family: 'GT America Bold', sans-serif;
  text-align: center;
  background: $primaryColor;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    p {
      margin-right: 40px;
    }
  }
}
