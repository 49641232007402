input:focus,
textarea:focus {
  outline: none;
}

.wrapper {
  width: 100%;
  display: flex;
}

.filter-count {
  display: block;
  width: 200px;
  margin-bottom: 1px;
  font-size: 13px;
}

.custom-field {
  display: flex;
  position: relative;
  min-width: 300px;
  max-width: 1500px;
  width: 88%;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: center !important;
  margin: 0 auto 25px auto;
}

.count-field {
  display: flex;
  align-items: flex-end;
  min-width: 300px;
  max-width: 1500px;
  width: 88%;
  margin: 0 auto 5px;
}

.count-field :first-child {
  flex: 1;
}

.count-field :nth-child(2) {
  flex: 3;
}

.count-field :last-child {
  flex: 1;
}

.count-field .submit-button {
  margin-top: 0;
}

.flag-fields {
  display: flex;
  flex-direction: row;
  justify-content: right;
  flex-wrap: nowrap;
  width: 100%;
}

.flag-fields .checkbox {
  width: 15px !important;
  height: 15px;
  margin: 0rem 1rem 1rem 1rem;
  padding: 0;
}

.search-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 25px;
  flex-wrap: wrap;
}

.dynamic-wrapper input, 
.dynamic-wrapper select,
.true__control {
  outline: none;
  width: 100%;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  border: none;
  padding-left: 5px;
  border: 1px solid #000 !important;
  margin-top: 25px;
  margin-right: 10px;
  background: #fff !important;
  border-radius: 0 !important;
  box-sizing: border-box;
  height: 40px;
}

.true__placeholder {
  font-size: 13px;
}

.true__indicators {
  position: absolute;
  right: 0;
}

.true__value-container {
  min-width: 100px;
}

.true__value-container--has-value {
  position: absolute !important;
  min-width: 700px;
  top: 45px;
  left: -12px;
}

.dynamic-wrapper .filter-input {
  margin-left: 0;
  box-sizing: border-box;
  width: 100%;
  padding-left: 35px;
  background-image: url('../images/search.svg') !important;
  background-position-y: center !important;
  background-position-x: 7px !important;
  background-repeat: no-repeat !important;
  margin-bottom: 14px;
}

.filter-input {
  width: 200px !important;
  margin: 25px 25px 0 0px;
}

.custom-dropdown {
  width: 456px;
}

.dynamic-wrapper select {
  width: 205px;
  background-image: url('../images/chevron-down.svg') !important;
  background-position-y: center !important;
  background-position-x: right !important;
  background-repeat: no-repeat !important;
}

select::placeholder {
  color: gray !important;
}
/* Weird bar in drop down select */
#react-select-1-input,
#react-select-2-input,
#react-select-3-input,
#react-select-4-input,
#react-select-5-input, 
#react-select-6-input, 
#react-select-7-input, {
  display: none;
}

[id^="react-select-"] {
  display: none;
}

.filter-button,
.clear-button {
  box-sizing: border-box;
  width: 4em;
  min-width: 120px;
  height: 40px;
  max-width: 200px;
  border: none;
  cursor: pointer;
}

.filter-button {
  letter-spacing: 1px;
  color: #fff;
  background: #000;
  font-size: 13px;
  font-weight: bold;
}

.clear-button {
  background: none;
  color: #000;
}

.section {
  margin: 0 auto;
  width: 100%;
}

.filter-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  flex: 2;
}

@media screen and (min-width: 1574px) {
  .filter-input {
    min-width: 200px;
  }
}

@media screen and (max-width: 1800px ) {
  .search-wrap {
    justify-content: flex-end !important;
  }

  .filter-input {
    margin: 0 25px 0 25px;
  }
}

@media screen and (max-width: 1574px) {

  .search-wrap {
    justify-content: flex-start;
  }

  .filter-input {
    margin-left: 0 !important;
    margin-right: 0;
  }

  .dynamic-wrapper select {
    margin-right: 25px;
  }

  .custom-dropdown {
    width: 430px;
  }

}

@media screen and (max-width: 1310px) {
  .search-wrap {
    flex-direction: column;
    align-items: flex-end;
  }

  .custom-dropdown {
    width: 400px;
  }

  .dynamic-wrapper input,
  .dynamic-wrapper select {
    width: 400px !important;
  }

  .dynamic-wrapper select {
    margin: 0 !important;
  }

  .filter-input {
    margin-right: 0;
  }

}

@media screen and (max-width: 730px) {

  .search-wrap {
    width: 100%;
    align-items: center;
  }

  .custom-field {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .search-wrap {
    justify-content: center !important;
  }

  .filter-buttons-wrapper {
    margin: 0 auto 25px auto;
  }

  .filter-buttons {
    -webkit-appearance: none;
    border-radius: 0;
    margin-top: 60px;
    align-self: center;
  }

  .filter-count {
    width: 100%;
  }

  .true__value-container--has-value {
    display: flex;
    flex-wrap: wrap;
    left: -100px;
  }

  .route-wrapper {
    margin: 45px 0 15px 0 !important;
  }
}

@media screen and (max-width: 630px) {
  .true__value-container--has-value {
    max-width: 400px;
    min-width: 300px;
    display: flex;
    flex-wrap: wrap;
    height: 100px;
    left: 60px;
  }

  .filter-buttons-wrapper {
    margin-top: 60px;
  }
}

@media screen and (max-width: 500px) {
  .custom-field {
    align-items: center;
  }

  .dynamic-wrapper input,
  .dynamic-wrapper select,
  .custom-dropdown {
    width: 250px !important;
  }

  .true__value-container--has-value {
    left: 10px;
  }
}