.station-inspection-wrapper {
  .rt-td:first-of-type,
  .rt-td:last-of-type {
    text-align: center;
  }
}

.pause-screen {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(57, 57, 57, 0.6);
  top: 0;
  left: 0;
  z-index: 100000;
  background-image: url('../../../images/pause.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10%;
  cursor: pointer;
}

.currentStation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid black;
  border-left: 0;
  border-top: 0;
  border-bottom-right-radius: 3px;
  height: 150px;
  width: 150px;
  z-index: -1;
  cursor: pointer;
  float: left;
  
  span {
    font-size: 98px;
    font-weight: bold;
    color: #787878;
  }

  p {
    font-size: 10px;
    font-weight: bold;
  }

}
.sku-form {
  margin-bottom: 25px;
  padding-top: 25px;

  .text-input {
    width: 200px;
  }

  .classy-button {
    margin-left: 10px;
    height: 33px;
    padding: 0;
  }

}

.status-card-wrap {
  display: inline-block;
  border: 1px solid;
  padding: 15px;
  min-width: 100px;
  max-width: 250px;
  margin-right: 20px;

  h4 {
    margin: 0;
  }
  div {
    text-align: left;
    span {
      font-size: 13px;

      &:first-of-type {
        font-weight: bold;

        &::after {
          content: ' - '
        }
      }
    }
  }
}