.tradeup-nav ul {
  display: flex;
  justify-content: center;
}

.tradeup-nav ul li {
  margin: 0 45px;
  padding: 0 5px;
}

.tradeup-nav ul li.active {
  border-bottom: 2px solid #00c160;
}

.tradeup-nav ul li button {
  font-size: 25px;
  background: none;
  border: none;
}

.decline-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: scroll;
}

.decline-sub {
  padding-bottom: 2px;
  margin-bottom: 10px;
  border-bottom: 1px solid #afafaf;
}

.sub-list {
  overflow: scroll;
  max-height: 500px;
}

.sId {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}