.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.small-gray-text {
  color: rgb(116, 116, 116);
  font-size: 12px;
  font-style: italic;
}

.wrap {
  display: flex;
  width: 100%;
  padding: 0 50px;
}

.classy-button {
  border: 1px solid;
  background: none;
  height: 30px;
  min-width: 80px;
  width: 100%;
  max-width: 200px;
  transition: background 0.2s, color 0.2s;
}

.classy-button:disabled {
  color: #afafaf;
}

.classy-button:hover:enabled {
  background: #000;
  color: #fff;
  cursor: pointer;
}

.remove-button {
  border: none;
  background: none;
  float: right;
  cursor: pointer;
}

.remove-button:hover {
  text-decoration: underline;
}

.marketing .list-wrap {
  width: 100%;
  padding: 0 50px;
}

.marketing .list-wrap li {
  margin: 5px 50px;
  padding: 3px 2px;
}

.even-li {
  background: #ddd;
}

.wrap div {
  flex: 1;
}

.success-edit {
  position: fixed;
  top: 0;
  background: #00c160;
  z-index: 10;
  color: #fff;
  width: 100%;
  height: 25px;
}

.edit-wrap {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.react-autosuggest__container {
  position: relative;
}
/* 
.react-autosuggest__input {
  box-sizing: border-box;
  width: 100%;
  min-width: 300px;
  max-width: 400px;
  height: 33px;
  padding: 0px 3px;
  font-size: 16px;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
} */

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  max-height: 500px;
  overflow: scroll;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

/* todo: this is global CSS so it affects every material-table instance */
/* o_O - jr */
.MuiFormControl-root {
  width: 100%;
}
