
.paypal-wrap caption {
  border: 1px solid;
  color: #000;
  font-weight: bold;
  font-size: 20px;
  height: 44px;
  line-height: 44px;
}

.paypal-wrap table {
  margin-top: 25px;
}

.button-container {
  margin-top: 20px;
  margin-left: 60px;
  margin-right: 60px;
  display: flex;
  justify-content: space-between;
}

.navlink-button {
  padding: 10px 20px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 4px;
  font-family: 'GT America Regular', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
}
.navlink-button:hover {
  background-color: #ddd;
  text-decoration: none;
}