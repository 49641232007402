@font-face {
  font-family: 'GT America Regular';
  src: local('GT America Regular'), url('../fonts/GT-America-Regular.woff') format('woff');
}

@font-face {
  font-family: 'GT America Bold';
  src: url('../fonts/GT-America-Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'GT America Condensed Bold';
  src: url('../fonts/GT-America-Condensed-Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'Bradford LL';
  src: local('Bradford LL'), url('../fonts/BradfordLLWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bradford LL Italic';
  src: local('Bradford LL'), url('../fonts/BradfordLLWeb-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Denim';
  src: local('Denim'), url('../fonts/Denim-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Denim Semibold';
  src: local('Denim'), url('../fonts/Denim-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

$tpcGreen: #00c160;

textarea,
select,
input,
button,
a {
  outline: none;
}

table,
tr,
td,
th,
span,
button,
div,
input,
textarea {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'GT America Regular', sans-serif;
  font-size: 16px;
}

a {
  color: $tpcGreen;

  &:hover {
    text-decoration: underline;
  }
}

button {
  -webkit-appearance: none;
  border-radius: 0;
}

.submit-button {
  -webkit-appearance: none;
  border-radius: 0;
  width: 4em;
  min-width: 120px;
  max-width: 200px;
  height: 40px;
  margin-top: 2em;
  box-sizing: border-box;
  border: none;
  align-self: center;
  font-size: 12px;
  letter-spacing: 1px;
  color: #fff;
  background-color: $tpcGreen;
  transition: background-color 0.2s;
  font-family: 'GT America Bold', sans-serif;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #008e47;
}

.submit-button:disabled {
  opacity: 0.5;
  background-color: $tpcGreen;
  cursor: inherit;
}

.title-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-title {
  font-family: 'GT America Regular', sans-serif;
  display: inline-block;
  margin-left: 25px;
}

.page-instructions {
  text-align: center;
  width: 50%;
  margin: 0 auto;
}

.text-input {
  padding-left: 5px;
  height: 33px;
  border: 1px solid black;
  max-width: 300px;
}

.text-input::placeholder,
.number-input::placeholder {
  color: #ccc;
}

.checkbox-wrap {
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
  max-width: 300px;
  width: 50%;
}

.checkbox-wrap input {
  margin-left: 15px;
}

.number-input {
  padding-left: 5px;
  height: 33px;
  border: 1px solid black;
  max-width: 100px;
}

.error-message {
  margin: 3px 0 0 5px;
  color: red;
  font-size: 0.6em;
}

.error-message-wrap {
  float: right;
  margin-right: 100px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Submit a Tech Ticket widget */
#jsd-widget {
  right: initial;
  left: 300px; // After the logo
  top: 5px;
}
