$tpcGreen: #00c160;
$status-text-indicator-color: #ffffff;
$status-indicator-background-color: #616161;

.carousel-root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100vh;

  .carousel-slider {
    display: flex;
    flex-direction: column;

    .slider-wrapper {
      height: 100%;
      display: flex;

      .slide {
        height: 100%;
        object-fit: contain;
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
        -moz-transition: opacity 0.5s ease-in-out;
        -webkit-transition: opacity 0.5s ease-in-out;

        & > .carousel-modal-wrapper {
          width: 100%;
          height: 100%;

          & > .modal-click {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
          & > .carousel-zoom-img {
            object-fit: contain;
            height: 100%;
          }
        }
        & > .carousel-no-zoom-img {
          object-fit: contain;
          height: 100%;
        }

        /* zoom component styling*/
        .iiz {
          &.carousel-zoom-container {
            height: 100%;
            width: 100%;
            width: 100%;
            margin-bottom: -5px;
            z-index: 8;

            & > div {
              height: 100%;

              & > .iiz__img {
                object-fit: contain;
                height: 100%;
              }
            }
          }
        }
      }
      .slide:not(.selected) {
        padding: 0 2px;
        opacity: 0;
      }
    }
    .arrow {
      position: absolute;
      top: 0;
      z-index: 5;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
    }
    .arrow.arrow--previous {
      left: 10px;
    }
    .arrow.arrow--next {
      right: 10px;
    }
  }
  .carousel {
    .thumbs-wrapper {
      .control-arrow {
        height: 50px;
        opacity: 1;
      }
      .thumbs {
        display: flex;
        flex-direction: row;
        flex-flow: nowrap;
        justify-content: center;
        padding: 0;

        & > .thumb {
          height: 56px;
          padding: 0;
          margin: 0 4px;
          border: none;
          transition: none;
        }
        & > .thumb:focus,
        .thumb:active,
        .thumb:focus-visible,
        .thumb:hover {
          border: none;
          outline: none;
          cursor: pointer;
        }
        & > .thumb.selected,
        .thumb.selected:hover {
          height: 56px;
          border: 2px solid $tpcGreen;
          outline-color: none;
          background-color: transparent;
        }
        & > .thumb:first-child {
          margin-left: 0;
        }
        & > .thumb:last-child {
          margin-right: 0;
        }
      }
    }
    .carousel-image {
      height: 100%;
      object-fit: cover;
      outline: none;
      border: none;
    }
    & > .carousel-status {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      color: $status-text-indicator-color;
      background-color: $status-indicator-background-color;
      padding: 0 0 10px 0;
      height: 22px;
      width: 41px;
      line-height: 22px;
      font-family: 'GT America Regular', sans-serif;
      font-size: 10px;
      text-shadow: none;
      margin: auto auto 0 auto;
      text-align: center;
      border-radius: 4px 4px 0 0;
    }
  }
}
